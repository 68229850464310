import HomeScreen from 'app/features/home-v3';
import loadHomePageV3 from 'app/lib/load-homepage-v3';
import type { InferGetServerSidePropsType, NextPage } from 'next';
import { GetServerSideProps } from 'next';
import { withUser } from '../auth/withUser';

export const getServerSideProps: GetServerSideProps = withUser(
  async (user, profile, identity, channel, api) =>
    async ({ preview, req }) => {
      if (user) {
        return {
          redirect: {
            destination: '/feed',
            permanent: false,
          },
        };
      }

      const page = await loadHomePageV3(preview, req.cookies['locale']);
      return {
        props: {
          me: user,
          profile,
          identity,
          token: api.token,
          channel,
          page,
        },
      };
    },
  true,
  'SkillHero'
);

const Home: NextPage = ({ page }: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  return <HomeScreen page={page} />;
};

export default Home;
