import { Theme } from 'app/constants/theme';
import { StyleSheet } from 'react-native';
import { CreateResponsiveStyle, DEVICE_SIZES } from 'rn-responsive-styles';
import { MEDIA_QUERY } from 'rn-responsive-styles/lib/types';

export default function CreateThemedStyle<
  DefaultStyles extends StyleSheet.NamedStyles<DefaultStyles>,
  OverrideStyles extends DefaultStyles,
>(
  webStyles: (theme: Theme) => DefaultStyles,
  overrideStyles?: (
    theme: Theme
  ) => Record<DEVICE_SIZES | MEDIA_QUERY, Partial<StyleSheet.NamedStyles<OverrideStyles>>>
) {
  return (theme: Theme) => CreateResponsiveStyle(webStyles(theme), overrideStyles?.(theme))();
}
