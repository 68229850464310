import { SKILLHERO_THEME } from 'app/constants/theme';
import { IHomePageV3Fields } from 'app/contentful';
import CreateThemedStyle from 'app/design/create-themed-style';
import useProfile from 'app/hooks/useProfile';
import { setCookie } from 'cookies-next';
import { useCallback } from 'react';
import { Image, Pressable, Text, View } from 'react-native';
import { DEVICE_SIZES, maxSize } from 'rn-responsive-styles';
import { MotiLink } from 'solito/moti';
import { MenuIcon, ProfileIcon } from '../icons';
type Props = {
  fields: IHomePageV3Fields;
  locale: string;
  setMenuVisible: (visible: boolean) => void;
};

const LOCALE_OPTIONS = [
  {
    name: 'EN',
    value: 'en-US',
  },
  {
    name: 'ES',
    value: 'es',
  },
];

export const Navigation: React.FC<Props> = ({ fields, locale, setMenuVisible }) => {
  const { theme } = useProfile();
  const styles = useStyles(theme);

  const handleSwitchLocale = useCallback((locale: string) => {
    setCookie('locale', locale);
    window.location.reload();
  }, []);

  return (
    <View style={styles.container}>
      <Pressable style={styles.left} onPress={() => setMenuVisible(true)}>
        {({ pressed, hovered }) => {
          const touched = pressed || hovered;
          return (
            <>
              <View style={styles.menuIcon}>
                <MenuIcon strokeColor={SKILLHERO_THEME.colors.neutral1} strokeWidth={1} />
              </View>
              <Text
                style={[
                  styles.menuText,
                  {
                    color: touched ? `${theme.colors.neutral1}B3` : theme.colors.neutral1,
                  },
                ]}
              >
                Menu
              </Text>
            </>
          );
        }}
      </Pressable>
      <View style={styles.center}>
        <MotiLink href="/" style={styles.logoContainer} containerStyle={styles.logoContainer}>
          <Image style={styles.logo} source={SKILLHERO_THEME.assets.logoWhite} />
          <Image style={styles.logoSmall} source={SKILLHERO_THEME.assets.logoSmallLight} />
        </MotiLink>
      </View>
      <View style={styles.right}>
        <MotiLink
          href={fields.topRightLogIn?.fields.linkUrl!}
          containerStyle={styles.loginContainer}
        >
          <Pressable style={styles.login}>
            {({ pressed, hovered }) => {
              const touched = pressed || hovered;
              return (
                <>
                  <View style={styles.profileIcon}>
                    <ProfileIcon strokeColor={SKILLHERO_THEME.colors.neutral1} />
                  </View>
                  <Text
                    style={[
                      styles.loginText,
                      {
                        color: touched ? `${theme.colors.neutral1}B3` : theme.colors.neutral1,
                      },
                    ]}
                  >
                    {fields.topRightLogIn?.fields.linkText}
                  </Text>
                </>
              );
            }}
          </Pressable>
        </MotiLink>
        <View style={styles.localeContainer}>
          {LOCALE_OPTIONS.map((item) => (
            <Pressable
              disabled={locale === item.value}
              key={item.value}
              style={[styles.locale, locale === item.value && styles.localeActive]}
              onPress={() => handleSwitchLocale(item.value)}
            >
              <Text style={styles.localeText}>{item.name}</Text>
            </Pressable>
          ))}
        </View>
      </View>
    </View>
  );
};

const useStyles = CreateThemedStyle(
  (theme) => ({
    container: {
      width: 1302,
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingTop: 24,
      paddingBottom: 64,
      userSelect: 'none',
      alignSelf: 'center',
    },
    left: {
      flexDirection: 'row',
      gap: 12,
      alignItems: 'center',
    },
    menuIcon: {
      width: 40,
      height: 40,
    },
    menuText: {
      fontFamily: 'Inter',
      fontSize: 14,
      fontWeight: '700',
      lineHeight: 20,
      color: theme.colors.neutral1,
    },
    center: {
      paddingTop: 12,
      justifyContent: 'center',
    },
    logoContainer: {
      width: 273,
      height: 32,
    },
    logo: {
      width: '100%',
      height: '100%',
    },
    logoSmall: {
      width: '100%',
      height: '100%',
      display: 'none',
    },
    right: {
      justifyContent: 'center',
      flexDirection: 'row',
      gap: 80,
    },
    loginContainer: {
      justifyContent: 'center',
    },
    login: {
      flexDirection: 'row',
      gap: 16,
      alignItems: 'center',
    },
    profileIcon: {
      width: 24,
      height: 24,
    },
    loginText: {
      fontFamily: 'Inter',
      fontSize: 16,
      lineHeight: 20,
      fontWeight: '700',
      color: theme.colors.neutral1,
    },
    localeContainer: {
      flexDirection: 'row',
      gap: 16,
      alignItems: 'center',
    },
    locale: {
      paddingHorizontal: 2.5,
      paddingVertical: 2,
      height: 24,
    },
    localeActive: {
      backgroundColor: theme.colors.primary1,
      borderRadius: 4,
    },
    localeText: {
      fontFamily: 'Inter',
      fontSize: 16,
      lineHeight: 20,
      fontWeight: '700',
      color: theme.colors.neutral1,
    },
  }),
  () => ({
    [maxSize(DEVICE_SIZES.LG)]: {
      container: {
        paddingHorizontal: 24,
        width: '100%',
      },
      left: {
        marginLeft: -6,
      },
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      menuText: {
        display: 'none',
      },
      loginText: {
        display: 'none',
      },
      center: {
        flex: 1,
        paddingTop: 0,
        paddingLeft: 16,
      },
      right: {
        gap: 24,
      },
      logoContainer: {
        width: 33.5,
        height: 40,
      },
      logo: {
        display: 'none',
      },
      logoSmall: {
        display: 'flex',
      },
    },
  })
);
