import { IconProps } from 'app/constants/icons';
import useProfile from 'app/hooks/useProfile';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const ProfileIcon: React.FC<IconProps> = ({
  outerFillColor = 'none',
  strokeColor,
  strokeWidth = 1.5,
}) => {
  const { theme } = useProfile();

  const _strokeColor = strokeColor ?? theme.colors.primary1;

  return (
    <Svg width="100%" height="100%" viewBox="0 0 24 24" fill={outerFillColor}>
      <Path
        d="M20 18.5C20 20.0294 16.5714 21 12 21C7.42857 21 4 20.0294 4 18.5C4 16.2692 8 15 12 15C16 15 20 16.5 20 18.5Z"
        stroke={_strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M12 12C14.4853 12 16.5 9.98528 16.5 7.5C16.5 5.01472 14.4853 3 12 3C9.51472 3 7.5 5.01472 7.5 7.5C7.5 9.98528 9.51472 12 12 12Z"
        stroke={_strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default ProfileIcon;
