import { IconProps } from 'app/constants/icons';
import useProfile from 'app/hooks/useProfile';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const MenuIcon: React.FC<IconProps> = ({
  outerFillColor = 'none',
  strokeColor,
  strokeWidth = 1.5,
}) => {
  const { theme } = useProfile();

  const _strokeColor = strokeColor ?? theme.colors.primary1;

  return (
    <Svg width="100%" height="100%" viewBox="0 0 24 24" fill={outerFillColor}>
      <Path
        d="M3 4.5H21"
        stroke={_strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M3 12.2H21"
        stroke={_strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M3 19.5H21"
        stroke={_strokeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default MenuIcon;
