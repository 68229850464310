type Props = {
  url?: string;
};

export const VideoBackground: React.FC<Props> = ({ url }) => {
  if (!url) {
    return null;
  }

  return (
    <video
      autoPlay
      muted
      loop
      playsInline
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        minHeight: 932,
        objectFit: 'cover',
      }}
    >
      <source src={url} />
    </video>
  );
};
