import { IconProps } from 'app/constants/icons';
import useProfile from 'app/hooks/useProfile';
import React from 'react';
import Svg, { Path } from 'react-native-svg';

export const CloseIcon: React.FC<IconProps> = ({
  outerFillColor = 'none',
  strokeColor,
  strokeWidth = 1.5,
}) => {
  const { theme } = useProfile();

  const _strokeColor = strokeColor ?? theme.colors.primary1;

  return (
    <Svg width="100%" height="100%" viewBox="0 0 24 24" fill={outerFillColor}>
      <Path
        d="M6 18L18 6"
        stroke={_strokeColor}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M18 18L6 6"
        stroke={_strokeColor}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default CloseIcon;
