import { SKILLHERO_THEME } from 'app/constants/theme';
import { IHomePageV3Fields } from 'app/contentful';
import CreateThemedStyle from 'app/design/create-themed-style';
import useProfile from 'app/hooks/useProfile';
import { Image, Pressable, Text, View, ViewStyle, useWindowDimensions } from 'react-native';
import { DEVICE_SIZES, maxSize } from 'rn-responsive-styles';
import { MotiLink } from 'solito/moti';
import { ChevronRightIcon } from '../icons';

type Props = {
  fields: IHomePageV3Fields;
  locale?: string;
};

export const Content: React.FC<Props> = ({ fields, locale = 'en-US' }) => {
  const { theme } = useProfile();
  const styles = useStyles(theme);
  const { width } = useWindowDimensions();

  return (
    <View style={styles.container}>
      <View style={styles.headlineContainer}>
        <Text style={styles.headline}>{fields.heroTitle1}</Text>
      </View>
      <MotiLink
        href={fields.joinCtaButton?.fields.ctaButtonUrl?.fields.buttonUrl!}
        containerStyle={
          [
            styles.joinContainer,
            fields.joinCtaButton?.sys.locale === 'es' && {
              maxWidth: 310,
            },
          ] as ViewStyle
        }
      >
        <Pressable
          style={({ pressed, hovered }) => {
            const touched = pressed || hovered;
            return [
              styles.join,
              touched && {
                backgroundColor: theme.colors.secondary1,
              },
            ];
          }}
        >
          <Text style={styles.joinText}>{fields.joinCtaButton?.fields.ctaButtonText}</Text>
          <View style={styles.joinIcon}>
            <ChevronRightIcon strokeColor={SKILLHERO_THEME.colors.primary3} />
          </View>
        </Pressable>
      </MotiLink>
      <View style={styles.spacer} />
      <View style={styles.figuresContainer}>
        {fields.homePageCTAs?.map((item) => (
          <MotiLink key={item.sys.id} href={item.fields.linkUrl!} style={styles.figureItem}>
            <Pressable style={styles.figureContainer}>
              <Text style={styles.figureTitle}>{item.fields.ctaTitle}</Text>
              <Text style={styles.figureDescription}>{item.fields.ctaSubtitle}</Text>
            </Pressable>
            <View style={styles.figureIcon}>
              <ChevronRightIcon strokeColor={SKILLHERO_THEME.colors.secondary2} strokeWidth={1} />
            </View>
          </MotiLink>
        ))}
      </View>
      {width >= 300 && (
        <>
          <View
            style={[
              styles.thisYou,
              locale === 'es' ? styles.thisYouSpanish : styles.thisYouDefault,
            ]}
          >
            <Text style={styles.thisYouText}>{fields.scriptArrowText}</Text>
          </View>

          <Image
            source={require('../../../../assets/home-v3/arrow.png')}
            style={styles.thisYouArrow}
          />
          <Image
            source={require('../../../../assets/home-v3/arrow-2.png')}
            style={styles.thisYouArrow2}
          />
        </>
      )}
    </View>
  );
};

const useStyles = CreateThemedStyle(
  (theme) => ({
    container: {
      width: 1302,
      flex: 1,
      alignSelf: 'center',
      paddingTop: 80,
      marginHorizontal: 'auto',
      paddingBottom: 85,
    },
    headlineContainer: {
      maxWidth: 975,
    },
    headline: {
      fontFamily: 'Roobert-Bold',
      fontWeight: '700',
      fontSize: 56,
      lineHeight: 64,
      color: theme.colors.neutral1,
    },
    joinContainer: {
      marginTop: 104,
      userSelect: 'none',
      maxWidth: 250,
    },
    join: {
      flexDirection: 'row',
      gap: 10,
      height: 64,
      paddingHorizontal: 24,
      paddingVertical: 10,
      borderRadius: 8,
      backgroundColor: theme.colors.secondary2,
      justifyContent: 'center',
      alignItems: 'center',
    },
    joinText: {
      fontFamily: 'Inter',
      fontSize: 20,
      lineHeight: 20,
      fontWeight: '700',
      textAlign: 'center',
      color: theme.colors.neutral8,
      flex: 1,
    },
    joinIcon: {
      width: 24,
      height: 24,
    },
    spacer: {
      flex: 1,
    },
    figuresContainer: {
      flexDirection: 'row',
      marginTop: 176,
      gap: 33,
    },
    figureItem: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      width: 412,
      height: 92,
      padding: 24,
      borderRadius: 8,
      backgroundColor: theme.colors.neutral1,
    },
    figureContainer: {
      flex: 1,
      justifyContent: 'center',
    },
    figureTitle: {
      fontFamily: 'Roobert-Bold',
      fontWeight: '600',
      fontSize: 18,
      lineHeight: 20,
      color: theme.colors.primary1,
    },
    figureDescription: {
      fontFamily: 'Inter',
      fontWeight: '500',
      fontSize: 14,
      lineHeight: 18,
      color: theme.colors.neutral7,
    },
    figureIcon: {
      width: 32,
      height: 32,
    },
    thisYouDefault: {
      right: 40,
      width: 272.5,
    },
    thisYouSpanish: {
      right: -30,
      height: 132,
    },
    thisYou: {
      position: 'absolute',
      top: 273,
      width: 400,
    },
    thisYouText: {
      fontFamily: 'Nothing You Could Do',
      fontWeight: '700',
      fontSize: 54,
      lineHeight: 58,
      color: theme.colors.secondary2,
    },
    thisYouArrow: {
      position: 'absolute',
      top: 413,
      right: 220,
      width: 230,
      height: 131.33,
    },
    thisYouArrow2: {
      display: 'none',
      position: 'absolute',
      top: 413,
      right: 220,
      width: 230,
      height: 131.33,
    },
  }),
  (theme) => ({
    [maxSize(DEVICE_SIZES.LG)]: {
      container: {
        width: '100%',
        paddingHorizontal: 24,
      },
      figureItem: {
        width: 350,
      },
    },
    [maxSize(DEVICE_SIZES.MD)]: {
      figuresContainer: {
        flexDirection: 'column',
        gap: 29,
      },
      figureItem: {
        width: '100%',
      },
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      headlineContainer: {
        paddingHorizontal: 18,
      },
      joinContainer: {
        marginHorizontal: 18,
      },
      join: {
        marginTop: 24,
      },
      spacer: {
        minHeight: 250,
      },
      container: {
        paddingHorizontal: 0,
        paddingBottom: 0,
      },
      figuresContainer: {
        backgroundColor: theme.colors.primary1,
        width: '100%',
        paddingTop: 37,
        paddingBottom: 88,
        paddingHorizontal: 18,
        alignSelf: 'flex-end',
      },
      thisYouDefault: {
        width: 143,
        top: 550,
        right: 50,
      },
      thisYouSpanish: {
        width: 150,
        top: 550,
        right: 30,
      },
      thisYouText: {
        fontSize: 24,
        lineHeight: 33.1,
      },
      thisYouArrow: {
        display: 'none',
      },
      thisYouArrow2: {
        display: 'flex',
        right: 50,
        top: 425,
      },
    },
    [maxSize(DEVICE_SIZES.XS)]: {
      headlineContainer: {
        maxWidth: 500,
      },
      headline: {
        fontSize: 36,
        lineHeight: 36,
      },
      thisYouDefault: {
        top: 670,
        right: 50,
      },
      thisYouSpanish: {
        top: 670,
        right: 50,
      },
      thisYouArrow2: {
        right: 50,
        top: 550,
      },
      figureItem: {
        height: 'auto',
      },
    },
  })
);
