import { Content } from 'app/components/home-v3/content';
import { Footer } from 'app/components/home-v3/footer';
import { MenuOverlay } from 'app/components/home-v3/menu-overlay';
import { Navigation } from 'app/components/home-v3/navigation';
import { VideoBackground } from 'app/components/home-v3/video-background';
import { LayoutFontGlobal } from 'app/components/layout-font-global';
import { SeoHead } from 'app/components/seo-head';
import { IHomePageV3 } from 'app/contentful';
import CreateThemedStyle from 'app/design/create-themed-style';
import useProfile from 'app/hooks/useProfile';

import { ScrollView } from 'moti';
import { useCallback, useState } from 'react';
import { View } from 'react-native';

type Props = {
  page: IHomePageV3;
};

const HomeScreen: React.FC<Props> = ({ page }) => {
  const {
    fields,
    sys: { locale },
  } = page;

  const { theme } = useProfile();
  const styles = useStyles(theme);
  const [menuVisible, setMenuVisible] = useState(false);
  const handleSetVisible = useCallback((visible: boolean) => {
    setMenuVisible(visible);
  }, []);

  return (
    <ScrollView contentContainerStyle={styles.container} scrollEnabled={!menuVisible}>
      <LayoutFontGlobal style={styles.layout}>
        <SeoHead seoSection={{ fields: fields.homePageSeo?.fields }} />
        <View style={styles.contentContainer}>
          <VideoBackground url={fields.heroBackgroundVideo?.fields.file.url} />
          <View style={styles.main}>
            <Navigation fields={fields} setMenuVisible={handleSetVisible} locale={locale} />
            <Content fields={fields} locale={page.sys.locale} />
          </View>
          <Footer fields={fields} />
          {menuVisible && (
            <View
              onClick={() => {
                handleSetVisible(false);
              }}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
            />
          )}
          <MenuOverlay
            locale={page.sys.locale}
            fields={fields}
            menuVisible={menuVisible}
            setMenuVisible={handleSetVisible}
          />
        </View>
      </LayoutFontGlobal>
    </ScrollView>
  );
};

const useStyles = CreateThemedStyle(
  (theme) => ({
    container: {
      minHeight: '100vh' as any,
      minWidth: '100%',
    },
    layout: {
      backgroundColor: theme.colors.primary1,
    },
    contentContainer: {
      width: '100%',
      height: '100%',
    },
    main: {
      minHeight: '100vh' as any,
      minWidth: '100%',
    },
  }),
  () => ({})
);

export default HomeScreen;
