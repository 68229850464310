import { SKILLHERO_THEME } from 'app/constants/theme';
import { IHomePageV3Fields } from 'app/contentful';
import CreateThemedStyle from 'app/design/create-themed-style';
import useProfile from 'app/hooks/useProfile';
import { useEffect, useRef } from 'react';
import { Animated, Pressable, ScrollView, Text, View, useWindowDimensions } from 'react-native';
import usePrevious from 'react-use/lib/usePrevious';
import { DEVICE_SIZES, maxSize } from 'rn-responsive-styles';
import { MotiLink } from 'solito/moti';
import { ChevronRightIcon, CloseIcon } from '../icons';

const MENU_OFFSET = -772;

type Props = {
  fields: IHomePageV3Fields;
  menuVisible: boolean;
  setMenuVisible: (visible: boolean) => void;
  locale?: string;
};

export const MenuOverlay: React.FC<Props> = ({
  fields,
  menuVisible,
  setMenuVisible,
  locale = 'en-US',
}) => {
  const { theme } = useProfile();
  const styles = useStyles(theme);
  const { width } = useWindowDimensions();

  const viewRef = useRef<View>(null);
  const animating = useRef(false);
  const slideAnim = useRef(new Animated.Value(MENU_OFFSET));
  const previousVisible = usePrevious(menuVisible);

  useEffect(() => {
    if (animating.current || previousVisible === menuVisible) {
      return;
    }

    animating.current = true;
    Animated.timing(slideAnim.current, {
      toValue: menuVisible ? 0 : MENU_OFFSET,
      duration: 100,
      useNativeDriver: true,
    }).start(() => {
      animating.current = false;
    });
  }, [menuVisible, previousVisible, slideAnim, width]);

  return (
    <Animated.View
      style={[
        styles.container,
        {
          transform: [
            {
              translateX: slideAnim.current,
            },
          ],
        },
      ]}
    >
      <ScrollView style={styles.scrollView} contentContainerStyle={styles.scrollViewContainer}>
        <View ref={viewRef}>
          <Pressable style={styles.closeIcon} onPress={() => setMenuVisible(false)}>
            <CloseIcon strokeColor={SKILLHERO_THEME.colors.neutral1} strokeWidth={0.8} />
          </Pressable>
          <View style={styles.contentContainer}>
            <View style={styles.menuContainer}>
              {fields.sideNavBar?.fields.firstLevelMenuItems?.map((item) => (
                <MotiLink
                  key={item.sys.id}
                  href={item.fields.linkUrl!}
                  style={styles.menuItem}
                  animate={({ hovered, pressed }) => {
                    'worklet';
                    const active = hovered || pressed;
                    return {
                      opacity: active ? 0.7 : 1,
                    };
                  }}
                  transition={{
                    type: 'timing',
                    duration: 100,
                  }}
                >
                  <Text
                    style={[styles.menuItemText, locale === 'es' && styles.menuItemTextSpanish]}
                  >
                    {item.fields.linkText}
                  </Text>
                  <View style={styles.chevronIcon}>
                    <ChevronRightIcon
                      strokeColor={SKILLHERO_THEME.colors.secondary2}
                      strokeWidth={1}
                    />
                  </View>
                </MotiLink>
              ))}
            </View>
            <View style={styles.meetContainer}>
              <Text style={styles.meetText}>{fields.sideNavBar?.fields.secondLevelMenuHeader}</Text>
              <View style={styles.meetLinks}>
                {fields.sideNavBar?.fields.secondLevelMenuItems?.map((item) => (
                  <MotiLink href={item.fields.linkUrl!} key={item.sys.id}>
                    <Pressable>
                      {({ pressed, hovered }) => {
                        const touched = pressed || hovered;

                        return (
                          <Text
                            style={[
                              styles.meetLink,
                              {
                                color: touched
                                  ? `${theme.colors.neutral1}B3`
                                  : theme.colors.neutral6,
                              },
                            ]}
                          >
                            {item.fields.linkText}
                          </Text>
                        );
                      }}
                    </Pressable>
                  </MotiLink>
                ))}
              </View>
              <View style={styles.buttons}>
                {fields.sideNavBar?.fields.buttons?.map((item) => (
                  <MotiLink
                    key={item.sys.id}
                    href={item.fields.ctaButtonUrl?.fields.buttonUrl!}
                    animate={({ hovered, pressed }) => {
                      'worklet';
                      const active = hovered || pressed;
                      return {
                        opacity: active ? 0.8 : 1,
                      };
                    }}
                    transition={{
                      type: 'timing',
                      duration: 100,
                    }}
                  >
                    <Pressable style={[styles.login]}>
                      <Text style={styles.loginText}>{item.fields.ctaButtonText}</Text>
                    </Pressable>
                  </MotiLink>
                ))}
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    </Animated.View>
  );
};

const useStyles = CreateThemedStyle(
  (theme) => ({
    container: {
      backgroundColor: theme.colors.primary1,
      width: 772,
      height: '100%',
      position: 'absolute',
      left: 0,
      top: 0,
    },
    scrollView: {
      overflow: 'hidden',
    },
    scrollViewContainer: {
      padding: 48,
      width: '100%',
    },
    closeIcon: {
      width: 44,
      height: 44,
    },
    contentContainer: {
      paddingLeft: 42,
      paddingTop: 55,
      gap: 55,
    },
    menuContainer: {
      gap: 48,
    },
    menuItem: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    menuItemText: {
      fontFamily: 'Roobert-Bold',
      fontSize: 64,
      lineHeight: 72,
      color: theme.colors.neutral1,
    },
    menuItemTextSpanish: {
      fontSize: 58,
      lineHeight: 66,
    },
    chevronIcon: {
      width: 56,
      height: 61,
      marginLeft: 56,
      paddingTop: 5,
    },
    meetContainer: {
      gap: 24,
    },
    meetText: {
      fontFamily: 'Roobert-Bold',
      fontWeight: '700',
      fontSize: 40,
      lineHeight: 48,
      letterSpacing: -3,
      color: theme.colors.neutral1,
    },
    meetLinks: {
      gap: 8,
    },
    meetLink: {
      fontFamily: 'Roobert-Bold',
      fontWeight: '700',
      fontSize: 28,
      lineHeight: 36,
      color: theme.colors.neutral6,
    },
    login: {
      width: 137,
      height: 40,
      paddingHorizontal: 16,
      paddingVertical: 4,
      borderRadius: 45,
      backgroundColor: theme.colors.neutral1,
      justifyContent: 'center',
    },
    loginText: {
      textAlign: 'center',
      color: theme.colors.neutral8,
      fontFamily: 'Inter',
      fontWeight: '600',
      fontSize: 16,
      lineHeight: 24,
    },
    buttons: {
      flexDirection: 'row',
      gap: 16,
    },
  }),
  () => ({
    [maxSize(DEVICE_SIZES.XS)]: {
      container: {
        width: '100%',
      },
      scrollViewContainer: {
        padding: 24,
      },
      contentContainer: {
        paddingLeft: 0,
      },
      closeIcon: {
        marginLeft: -6,
      },
      menuContainer: {
        gap: 24,
      },
      menuItemText: {
        fontSize: 40,
        lineHeight: 48,
      },
      chevronIcon: {
        marginLeft: 24,
      },
      meetText: {
        fontSize: 28,
        lineHeight: 36,
      },
      buttons: {
        flexDirection: 'column',
      },
    },
  })
);
